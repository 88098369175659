import React from "react"
import { Link } from "gatsby"

class LeftPanel extends React.Component {
  render() {
    const { posts } = this.props
    return (
      <div className="knowledgebase-sidebar-category-list">
        <p className="h4 font-weight-medium mt-0">Sections</p>
        <div className="knowledge-sidebar-section h-100">
          <ul className="knowledge-sidebar-list list-unstyled m-0">
            {posts.map(({ node }) => {
              let subcat = node.subCategory
              return (
                <li key={node.id} className="knowledge-link-level-0">
                  <div className="knowledge-subheading mb-2">
                    <span
                      className={`${node.icon} knowledge-widget-icon mr-2 mt-1`}
                    ></span>
                    <Link
                      activeClassName="current-active"
                      className="font-weight-medium"
                      to={`/knowledge-base/${node.slug.current}/`}
                    >
                      {node.title}
                    </Link>
                  </div>
                  {subcat.length ? (
                    <ul className="list-unstyled pl-2">
                      {subcat.slice(0, 9).map((item, idx) => {
                        return (
                          <li key={item.id} className="knowledge-link-level-1">
                            <div className="knowledge-subheading">
                              <span
                                aria-hidden="true"
                                className="knowledge-heading-icon fa fa-folder-o mt-1 mr-2"
                              />
                              <Link
                                activeClassName="current-active"
                                to={`/knowledge-base/${item.slug.current}/`}
                              >
                                {item.title}
                              </Link>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
}

export default LeftPanel
